export default [
    {
        key: 'name',
        label: 'field.supplierName',
        type: 'text',
        operator: '%',
    },
    {
        key: 'phoneNumber',
        label: 'field.phoneNumber',
        type: 'text',
        operator: '%',
    },
];