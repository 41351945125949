export default [
    {
      key: 'increasement',
      sortable: false,
      sortField: '',
      label: '#',
      thClass: 'w-50px',
    },
    {
      key: 'name',
      sortable: true,
      sortField: 'name',
      label: 'field.supplierName',
      stickyColumn: true,
      variant: 'light',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'phoneNumber',
      label: 'field.phoneNumber',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'address',
      label: 'field.address',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'status',
      label: 'field.status',
    },
    {
      key: 'createdAt',
      sortable: true,
      sortField: 'createdAt',
      label: 'field.createDate',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'createdBy',
      label: 'field.createdBy',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'updatedAt',
      sortable: true,
      sortField: 'updatedAt',
      label: 'field.updateDate',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'updatedBy',
      label: 'field.updatedBy',
      thClass: 'text-left',
      tdClass: 'text-left',
    },
    {
      key: 'actions',
      label: 'general.action',
    },
];